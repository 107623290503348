import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import config from '../config'
import './styles/navbar.scss'
import logo from '../assets/logo.png'

const Navbar = () => {
  const location = useLocation()
  
  const getClassName = page => {
    return location.pathname === page ? 'navbar__menubar--active' : 'navbar__menubar'
  }
  
  return (
    <div className="navbar">
      <div className='navbar__links'>
        <div className='navbar__links__logo'>
          <img src={logo}/>
        </div>
        <div className='navbar__links__container'>
          <Link to={config.pages.home} className={getClassName(config.pages.home)}>
          <p>HOME</p>
          </Link>
          <Link to={config.pages.subservices} className={getClassName(config.pages.subservices)}>
          <div className="dropdown">
            <p>SERVICES</p>
              <div className="dropdown-content">
              <Link to={config.pages.subservices}>
              <p>Tax Services</p>
              </Link>
              <Link to={config.pages.services}>
              <p>Accounting Services</p>
              </Link>
              </div>
          </div>
          </Link>
          <Link to={config.pages.about} className={getClassName(config.pages.about)}>
            <p>ABOUT US</p>
          </Link>
          <Link to={config.pages.contact} className={getClassName(config.pages.contact)}>
            <p>CONTACT US</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Navbar
