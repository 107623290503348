import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import config from '../config'
import './styles/mobile-navbar.scss'

const MobileNavbar = ({ isOpen, onChildClick }) => {
  const location = useLocation()
  
  const getClassName = page => {
    return location.pathname === page ? 'm-navbar__dropdown__list-menu--active' : 'm-navbar__dropdown__list-menu'
  }
  
  return (
    <div className="m-navbar">
      {isOpen ? (
        <div className="m-navbar__dropdown">
          <div className="m-navbar__dropdown__list">
            <Link to={config.pages.home}
                  className={getClassName(config.pages.home)}
                  onClick={onChildClick}>
              Home
            </Link>
            <Link to={config.pages.about}
                  className={getClassName(config.pages.about)}
                  onClick={onChildClick}>
              About Us
            </Link>
            <Link to={config.pages.services}
                  className={getClassName(config.pages.services)}
                  onClick={onChildClick}>
              Services
            </Link>
            <Link to={config.pages.contact}
                  className={getClassName(config.pages.contact)}
                  onClick={onChildClick}>
              Contact Us
            </Link>
            <div className="m-navbar__footer">
              <div className="m-navbar__footer-text">Kantor Konsultan Pajak<br/>Anthony Aris & Rekan</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default MobileNavbar
