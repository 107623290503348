import React, { useState, useContext, useEffect } from 'react'

const DeviceTypeContext = React.createContext()
const DeviceTypeUpdateContext = React.createContext()

const useDeviceType = () => {
  return useContext(DeviceTypeContext)
}

const DeviceTypeProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  
  const toggleDeviceType = () => {
    if (window.innerWidth <= 760) {
      return setIsMobile(true)
    }
    return setIsMobile(false)
  }
  
  useEffect(() => {
    toggleDeviceType()
  }, [window.innerWidth])
  
  return (
    <DeviceTypeContext.Provider value={isMobile}>
      <DeviceTypeUpdateContext.Provider value={toggleDeviceType}>
        {children}
      </DeviceTypeUpdateContext.Provider>
    </DeviceTypeContext.Provider>
  )
}

export {
  useDeviceType,
  DeviceTypeProvider
}
