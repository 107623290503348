import React, { useState } from 'react'
import { useDeviceType } from '../context/DeviceTypeContext'
import { Fade as Hamburger } from 'hamburger-react'
import { Navbar, MobileNavbar } from './'
import logo from '../assets/logo.png'
import './styles/header.scss'

const Header = () => {
  const isMobile = useDeviceType()
  const [isOpen, setOpen] = useState(false)
  
  const closeNavbar = () => {
    setOpen(false)
  }
  
  return (
    <div className="header">
      <div className="header__heading">
        {isMobile ? (
        <img src={logo} className="header__heading__logo"/>
        ) : null }
        {/*<div className="header__heading__text">
          Kantor Konsultan Pajak<br/>Anthony Aris & Rekan
        </div>*/}
        {isMobile ? (
          <div className="header__heading__button">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        ) : null}
      </div>
      <div className="header__navbar">
        {isMobile ? (
          <MobileNavbar isOpen={isOpen} onChildClick={closeNavbar} />
        ) : (
          <Navbar />
        )}
      </div>
    </div>
  )
}

export default Header
