import React from 'react'
import { useDeviceType } from '../context/DeviceTypeContext'
import glassesImage from '../assets/glasses.jpeg'
import './styles/about-us.scss'

const AboutUs = () => {
  const isMobile = useDeviceType()
  
  return (
    <div className="about">
      <div className="about__wrapper">
        <div className="about__content">
          <span className="about__content__heading">About Us</span>
          <p className="about__content__paragraphs">
          KKP Anthony Aris and Rekan as known as Luminos Consulting, a full-service accounting, tax and, management consultant. Luminos Consulting was established in 2019 and is now located in West Jakarta, Indonesia.
          </p>
          <p className="about__content__paragraphs">
          With our Partner’s experiences, we’ve always believed in providing the best service possible. We also ensure that our team stay up to date with the regulations in Indonesia. Our team will give you the best effort in handling matter and we believe that our team could cooperate well with you.
          </p>
          <p className="about__content__paragraphs">
          We are here not only to give you the services, but also to educate and share knowledge of accounting and taxation. We hope that every entity can understand the bookkeeping and taxation so that they can run their business well.
          </p>
        </div>
        
        {isMobile ? null : (
          <div className="about__visuals">
            <img src={glassesImage} className="about__visuals__image"/>
          </div>
        )}
      </div>
    </div>
  )
}

export default AboutUs
