import React from 'react'
import { useDeviceType } from '../context/DeviceTypeContext'
import config from '../config'
import './styles/contact-us.scss'

const ContactUs = () => {
  const isMobile = useDeviceType()
  
  return (
    <div className="contact">
      <div className="contact__wrapper">
        <div className="contact__info">
          <span className="contact__info__heading">
            Our Address
          </span>
          
          <div className="contact__info__container">
            <span className="contact__info__address">
              KKP Anthony Aris dan Rekan
            </span>
            <span className="contact__info__address">
              Komplek Graha Kencana, Blok GK Lantai 3C
            </span>
            <span className="contact__info__address">
              Jl. Raya Perjuangan No. 88, Jakarta Barat
            </span>
            <span className="contact__info__address">
              Phone +62 21 5011 0195
            </span>
          </div>
          
          <div className="contact__info__maps">
            <iframe
              src={config.external.maps}
              width={isMobile ? 325 : 425}
              height={isMobile ? 225 : 275}
              allowFullScreen=""
              loading="lazy" />
          </div>
        </div>
        
        <div className="contact__form">
          <span className="contact__form__heading">
            Email Us
          </span>
          <div className="contact__form__container">
            <form id="contactForm"
                  action={config.external.form}
                  method="post"
                  name="simple-contact-form">
              <span className="contact__form__label">Name</span>
              <input type="text"
                     name="name"
                     placeholder="Type your name here"
                     className="contact__form__input"/>
              <span className="contact__form__label">Email Address</span>
              <input type="email"
                     name="_replyto"
                     placeholder="Type your email address here"
                     className="contact__form__input" />
              <span className="contact__form__label">Subject</span>
              <input type="text"
                     name="_subject"
                     placeholder="Type your Subject here"
                     className="contact__form__input" />
              <span className="contact__form__label">Message</span>
              <textarea name="message"
                        cols="45"
                        rows="5"
                        placeholder="Type your Messages here"
                        className="contact__form__input-area" />
              <button type="submit"
                      form="contactForm"
                      value="SEND"
                      className="contact__form__submit">
                <span className="contact__form__submit--text">Submit</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
