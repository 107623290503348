import React from 'react'
import './styles/footer.scss'
import instagram from '../assets/instagram.png'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer__title'>KKP ANTHONY ARIS & REKAN</div>
        <div className='footer__title'>MON - FRI</div>
        <div className='footer__title'>✆ +62 21 5011 0195</div>
        <div className='footer__title'>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
          <a href="https://www.instagram.com/luminosindonesia/" class="fa fa-instagram">&nbsp;luminosindonesia</a>
        </div>
    </div>
  );
};

export default Footer;