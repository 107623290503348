module.exports = {
  pages: {
    home: '/',
    about: '/about-us',
    services: '/services',
    subservices: '/SubServices',
    contact: '/contact-us'
  },
  external: {
    maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.548059903813!2d106.76321025071539!3d-6.191173862359761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f7c3fe6f73f9%3A0x4c6754942a146caa!2sKKP%20ANTHONY%20ARIS%20DAN%20REKAN%20(LUMINOS)!5e0!3m2!1sid!2sid!4v1622304753652!5m2!1sid!2sid',
    form: 'https://formspree.io/f/mzbyrgqd'
  }
}
