import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { DeviceTypeProvider } from './context/DeviceTypeContext'
import { Home, AboutUs, Services, ContactUs, SubServices } from './pages'
import { Header,Footer } from './components'
import config from './config'

const App = () => {
  return (
    <DeviceTypeProvider>
      <HashRouter>
        <div className="app">
          <div className="app_header">
            <Header />
          </div>
          <div className="app_content">
            <Switch>
              <Route exact path={config.pages.home} component={Home} />
              <Route path={config.pages.about} component={AboutUs} />
              <Route path={config.pages.services} component={Services} />
              <Route path={config.pages.subservices} component={SubServices} />
              <Route path={config.pages.contact} component={ContactUs} />
            </Switch>
          </div>
          
          <div className="app_footer">
            <Footer />
          </div>
        </div>
      </HashRouter>
    </DeviceTypeProvider>
  )
}

export default App
