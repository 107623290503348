import React from 'react'
import economicIcons from '../assets/economic-icons.png'
import './styles/home.scss'

const Home = () => {
  return (
    <div className="home">
      <div className="home__economic">
        <img src={economicIcons} className="home__economic__image"/>
      </div>
      <div className="home__mission">
        <div className="home__mission__wrapper">
          <span className="home__mission__text">
            "Your trusted companion to build a better business"
          </span>
        </div>
      </div>
    </div>
  )
}

export default Home
