import React from 'react'
import { useDeviceType } from '../context/DeviceTypeContext'
//import { Link, useLocation } from 'react-router-dom'
//import config from '../config'
import './styles/services.scss'
import servicebg from '../assets/services-bg.jpg'

const SubServices = () => {
  const isMobile = useDeviceType()
  //const location = useLocation()
  
  /*const getClassName = page => {
    return location.pathname === page ? 'navbar__menu--active' : 'navbar__menu'
  }*/
  
  return (
    <div className="services">
      <div className="services__wrapper">
        <div className="services__content">
            <p className="services__content__heading">
            Tax Services
            </p>
            <ul>
              <li>Monthly compliance tax</li>
                <p>
                  We provide monthly compliance tax which helps the company to do the tax submission. We will help the company to calculate their monthly taxes, such as tax art. 21, art. 23, art. 4(2), VAT, etc. We do the calculations based on data from the company. We help the company to do the monthly tax submission as well.
                </p>
              <li>Annual corporate tax</li>
                <p>
                We provide annual corporate tax which helps the company to do the company tax return. We will calculate the annual company tax and prepare the tax form to do the submission based on data from the company.
                </p>
              <li>Annual personal tax</li>
                <p>
                We provide annual personal tax which helps the person to do the tax submission. We will help to prepare the cashflow, and also the tax form to do the submission. We prepare and fill the tax form based on data given by client.
                </p>
              <li>Tax consultation</li>
                <p>
                We provide tax consultation for the company. With this service, we can do tax planning for the company or update the tax regulations for the company. So, with the punctual update of tax regulation, can minimalize the tax issue in a company.
                </p>
              <li>Tax audit service assistance</li>
                <p>
                We provide tax audit service assistance for the company that is doing tax refund or being audited by tax audit. We can assist in preparing the tax audit data, and also answer the tax findings.
                </p>
            </ul>
        </div>

        {isMobile ? null : (
          <div className='services__visuals'>
            <img src={servicebg} className="services__visuals__image"/>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubServices
